
/**
 *Dashboard Component.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";

import { mapGetters } from "vuex";
import ProjectService from "@/components/dashboard/ProjectServiceDashboard.vue";
import InventoryHealth from "@/components/dashboard/InventoryHealth.vue";
import UsersHealth from "@/components/dashboard/UsersHealth.vue";
import UsersStats from "@/components/dashboard/UsersStats.vue";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "Dashboard",
  components: {
    ProjectService,
    InventoryHealth,
    UsersHealth,
    UsersStats
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class Dashboard extends Vue {
  /*----------  Vuex   ----------*/
  readonly getUserAccess!: UserAccess;
}
