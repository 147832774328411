<script>
import { Doughnut } from "vue-chartjs";
import { mapGetters } from "vuex";

export default {
  extends: Doughnut,
  props: {
    items: {
      type: Object,
      default: function () {
        return {};
      }
    },
    colors: {
      type: Array,
      default: () => ["#FFFFFF", "#FFFFFF"]
    },
    position: {
      type: String,
      default: "right"
    },
    legend: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    items() {
      this.renderChart(
        {
          labels: Object.keys(this.items).map(
            (item, i, key) => `${this.updateLabel(item, i, key)}(${this.items[item]})`
          ),
          datasets: [
            {
              backgroundColor: this.colors,
              data: Object.values(this.items),
              borderColor: this.CURRENT_THEME === "dark" ? "#353C41" : "#ffffff"
            }
          ]
        },
        {
          legend: {
            display: this.legend,
            position: this.position,
            align: "center",
            labels: {
              boxWidth: 16,
              boxHeight: 16,
              usePointStyle: true,
              fontColor: this.CURRENT_THEME === "dark" ? "#fafafa" : "#353C41",
              textAlign: "right"
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 70,
          tooltips: { enabled: false }
          // hover: { mode: null }
        }
      );
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: Object.keys(this.items).map((item, i, key) => `${this.updateLabel(item, i, key)}(${this.items[item]})`),
        datasets: [
          {
            backgroundColor: this.colors,
            data: Object.values(this.items),
            borderColor: this.CURRENT_THEME === "dark" ? "#353C41" : "#ffffff"
          }
        ]
      },
      {
        legend: {
          display: this.legend,
          position: this.position,
          labels: {
            boxWidth: 16,
            boxHeight: 16,
            usePointStyle: true,
            fontColor: this.CURRENT_THEME === "dark" ? "#fafafa" : "#353C41",
            textAlign: "right"
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70
      }
    );
  },
  computed: {
    ...mapGetters("Theme", {
      CURRENT_THEME: "GET_CURRENT_THEME"
    })
  },
  methods: {
    updateLabel(label) {
      let item = label.charAt(0).toUpperCase() + label.slice(1);
      return `${item} `;
    }
  }
};
</script>
